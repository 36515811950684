<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">
        <!-- Table Top -->
<!--        <b-row>

          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />

            </div>
          </b-col>
        </b-row>-->

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(name)="data">
          [#{{data.item.id}}] [#{{data.item.product_id}}]{{data.item.name}}
        </template>
        <template #cell(is_gift)="data">
          {{ getCodeLabel('yesno', data.item.is_gift) }}
        </template>
        <!-- Columns -->
        <template #cell(product_code)="data">
          {{ data.item.ext.product.code }}
        </template>

        <!-- Columns -->
        <template #cell(inventory_type)="data">
          {{ getCodeLabel('inbound_inventory_type', data.item.inventory_type) }}
        </template>

        <!-- Column: inboundForm -->
        <template #cell(inboundForm)="data">
          <b-link
              @click="toggleDetail(data)"
          >
            <feather-icon
                icon="AlignJustifyIcon"
                size="16"
                class="align-middle text-body"
            />
          </b-link>
        </template>


        <template #row-details="data">
          <b-card header="入库">
            <inbound-form
                ref="inboundForm" :condition="data.item" :warehouseId="warehouseId" v-on:refresh="hideAndRefetchData(data)"
            >
            </inbound-form>

          </b-card>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  second
} from '@core/utils/filter'
import purchaseinbounditemUseList from './warehouseUseList'
import purchaseinbounditemStore from './purchaseinbounditemStore'
import { useToast } from 'vue-toastification/composition'
import { useRouter } from '@core/utils/utils'
import InboundForm from '@/views/apps/purchaseinbounditem/inboundForm'
import StockInboundItemList from '@/views/apps/stockinbounditem/inboundItem-modal/StockInboundItemList'

export default {
  props: {
    inboundId :{
      type : Number,
    },
  },
  components: {
    StockInboundItemList,
    InboundForm,
    BCard,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      maxFlag: false,
    }
  },
  methods: {
    showModal() {
      this.$refs['modal'].show()
    },
    toggleDetail(data){
      data.toggleDetails()
    }
  },
  setup: function (props) {
    const toast = useToast()
    const {
      route,
      router
    } = useRouter()
    let inbound_id;
    if (props.inboundId != null) {
      inbound_id = props.inboundId
    } else {
      inbound_id = route.value.query.id || 0
    }
    const warehouseId = ref(parseInt(route.value.query.warehouseId) || 0)
    // Register module
    if (!store.hasModule('purchaseinbounditem')) store.registerModule('purchaseinbounditem', purchaseinbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinbounditem')) store.unregisterModule('purchaseinbounditem')
    })

    const save = async function (item) {

      if (this.$refs.inboundForm !== undefined && this.$refs.inboundForm.items.length !== 0) {
        let items = this.$refs.inboundForm.items
        let success = await this.$refs.inboundForm.validateAll()
        if (success) {
          let batchno = ''
          let productiondate = ''
          let expirydate = ''
          let batchqty = ''
          items.forEach(item => {
            batchno += item['batch_no'] + ','
            productiondate += item['production_date'] + ','
            expirydate += item['expiry_date'] + ','
            batchqty += item['qty'] + ','
          })
          batchno = batchno.substring(0, batchno.length - 1)
          productiondate = productiondate.substring(0, productiondate.length - 1)
          expirydate = expirydate.substring(0, expirydate.length - 1)
          batchqty = batchqty.substring(0, batchqty.length - 1)
          store.dispatch('purchaseinbounditem/inbound', {
            itemid: item.inbounditem_id,
            batchno: batchno,
            productiondate: productiondate,
            expirydate: expirydate,
            batchqty: batchqty,
          })
              .then(res => {
                if (res.data.code === 0) {
                  toast.success('数据已保存!')
                  refetchData()
                } else {
                  alert(res.data.data)
                }
              })
        } else {
          toast.error('请填写缺失字段')
        }
      } else {
        toast.error('请先进行入库数据填写')
      }
    }

    const hideAndRefetchData = function (data) {
      data.toggleDetails()
      refetchData()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI

    } = purchaseinbounditemUseList({
      inbound_id: inbound_id
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      save,
      warehouseId,
      hideAndRefetchData,
    }
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
